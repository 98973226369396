// $dark-color: #ff8583;
// $light-color: #ffeded;
// $font-color: #ff5350;
// $dark-color: #a0a2fa;
// $light-color: #fffeed;
// $font-color: black;

$dark-color: #88b1ff;
$light-color: #cbddff;
// $light-color: #fffeed;
$font-color: #0c2658;

html {
    scroll-behavior: smooth;
}

p {
    text-align: justify;
}

body {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 3vw;
    color: $font-color;
}

.mainHeader {
    position: absolute;
    right: 6vw;
    width: inherit;
    overflow: visible;
    // transform: translate(-100px, 0);

    div {
        padding: 0;
        margin: 0;
        float: right;
    }

    p {
        width: fit-content;
    }
}

.headerImages {
    margin: 10px 10px 0 10px;
    
    img {
        border: 3px solid $font-color;
        border-radius: 25px;
    }
}

#PerProj {
    img {
        border: 3px solid $font-color;
        border-radius: 10px;
    }
}

.BaseApp {
    /* background-image:linear-gradient(white, #ff8583); */
    background-color: #ffcac9;
    background-color: $light-color;
    // background-color: #1f1f1f;
    // background-image:linear-gradient(#ff5350, #111111);
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
}

.iconRow {
    margin: 0;
    text-align: right;
    margin-right: 2%;
    position: absolute;
    bottom: 1%;

    img {
        width: 6%;
    }
}

.react-pdf__Document {
    position: relative;
    float: right;
    margin: 0.5%;
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Document:hover .page-controls {
    opacity: 1;
  }

.page-controls {
    position: absolute;
    bottom: 3%;
    left: 50%;
    background: white;
    opacity: 1;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
  }
  .page-controls span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
  }
  .page-controls button {
    width: 44px;
    height: auto;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
    outline: none;
  }
  .page-controls button:enabled:hover {
    cursor: pointer;
  }
  .page-controls button:enabled:hover,
  .page-controls button:enabled:focus {
    background-color: #e6e6e6;
  }
  .page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

.leftDoc {
    float: left;
}

.centeredRow {
    margin: 0 auto;
    width: max-content;
}

.cancelATag, .cancelATag:hover {
    color: inherit;
    text-decoration: none;
    background: inherit;
}

#PerProj, #TechSkills {
  background-color: $light-color;
}

#Education, #IndExp {
  background-color: $dark-color;
}

@mixin long-shadow($type, $color, $length, $fadeout: true, $skew: false, $direction: right){
  $shadow: '';
  @if $skew == false or $type == text{
      @if $direction == right {
      @for $i from 0 to $length - 1 {
          $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' + $color + ',';
      }
      }
      @if $direction == left {
      @for $i from 0 to $length - 1 {
          $shadow: $shadow + $i * -1 + 'px ' + $i + 'px 0 ' + $color + ',';
          }
      }
      }
          
      @if $fadeout == true{
      @for $i from 1 to $length - 1 {
      @if $type == text or $skew == false{
          @if $direction == right{
          $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' +       rgba($color, 1 - $i / $length) + ',';
          }
          @if $direction == left{
          $shadow: $shadow + $i * -1 + 'px ' + $i + 'px 0 ' +       rgba($color, 1 - $i / $length) + ',';
          }
      }
      @if ($type == box) and $skew == true{
          @if $direction == right {
          $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' + $i * .2 + 'px ' + rgba($color, 1 - $i / $length) + ',';
          }
          @if $direction == left {
          $shadow: $shadow + $i * -1 + 'px ' + $i + 'px 0 ' + $i * .2 + 'px ' + rgba($color, 1 - $i / $length) + ',';
          }
      }
  }
  $shadow: $shadow + $length + 'px ' + $length + 'px 0 ' + rgba($color, 0);
  }
  @if $fadeout == false{
      @if $skew == true and ( $type == box ){
      @for $i from 0 to $length - 1 {
              $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' + $i * .1 + 'px ' + $color + ',';
      }
      }
      $shadow: $shadow + $length + 'px ' + $length + 'px 0 ' + rgba(0,0,0,0);
  }
  $shadow: unquote($shadow);
  @if $type == 'box' {box-shadow: $shadow;}
  @if $type == 'text' {text-shadow: $shadow;}
  }
  
  .shadow {
      padding: 0 1% 0 0;
      color: white;
      @include long-shadow(text, $font-color, 15, false, false, right);
      display: inline-block;
      float: left;
      margin-right: 2%;
      width: max-content;
  
      p {
          font-size: 4vw;
          margin: 0;
          padding:0;
      }
  
      div {
          width: 0;
          height: 0;
          margin: 0;
          padding:0;
      }
  }
  
  .smallShadow {
      @include long-shadow(text, $font-color, 10, false, false, right);
  }
  
  .shadow:after {
      clear: both;
  }
  
  .shadowHover {
      cursor: pointer;
      transition: all .2s;
  }
  
  .shadowHover:hover {
      @include long-shadow(text, $font-color, 60, false, false, right);
      transform: translate(0, -42px);
      padding: 0 6% 4% 0;
      // overflow: visible;
  
      // div {
      //     width: calc(100% + 4.6vw);
      //     height: 60px;
      //     margin: 0;
      //     padding:0;
      // }
  
      // p {
      //     width: calc(100% + 4.6875vw);
      //     height: calc(100% + 31.25vw);
      //     margin: 0;
      //     padding:0;
      // }
  }